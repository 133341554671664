// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ended-js": () => import("./../src/pages/ended.js" /* webpackChunkName: "component---src-pages-ended-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quick-start-js": () => import("./../src/pages/quick-start.js" /* webpackChunkName: "component---src-pages-quick-start-js" */),
  "component---src-pages-share-played-js": () => import("./../src/pages/share-played.js" /* webpackChunkName: "component---src-pages-share-played-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-test-js": () => import("./../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

